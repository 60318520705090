const mapaDetalleEmpleado = {
  'ID': 'datosLaborales.ID',
  'nombre': 'datosPersonales.nombre',
  'apellidoPaterno': 'datosPersonales.apellidoPaterno',
  'apellidoMaterno': 'datosPersonales.apellidoMaterno',
  'nss': 'datosPersonales.nss',
  'curp': 'datosPersonales.curp',
  'rfc': 'datosPersonales.rfc',
  'estadoCivil': 'datosPersonales.estadoCivil',
  'sexo': 'datosPersonales.sexo',
  'calle': 'datosPersonales.direccion.calle',
  'numeroExterior': 'datosPersonales.direccion.numeroExterior',
  'numeroInterior': 'datosPersonales.direccion.numeroInterior',
  'colonia': 'datosPersonales.direccion.colonia',
  'codigoPostal': 'datosPersonales.direccion.codigoPostal',
  'municipio': 'datosPersonales.direccion.municipio',
  'estado': 'datosPersonales.direccion.estado',
  'banco': 'datosBancarios.banco',
  'cuenta': 'datosBancarios.cuenta',
  'clabe': 'datosBancarios.clabe',
  'puesto': 'datosLaborales.puesto',
  'sueldo': 'datosLaborales.sueldo',
  'ingreso': 'datosLaborales.ingreso',
  'fecha_nacimiento': 'datosPersonales.fecha_nacimiento'
};

module.exports = { mapaDetalleEmpleado }
