
const data = [
    {
        ID: 'XXXX',
        Nombre: 'Jose Dominguez',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$500'
    },
    {
        ID: 'XXXX',
        Nombre: 'Perla Lopez',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$400'
    },
    {
        ID: 'XXXX',
        Nombre: 'Cristopher Rocha',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$350'
    },
    {
        ID: 'XXXX',
        Nombre: 'Victor Dominguez',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$560'
    },
    {
        ID: 'XXXX',
        Nombre: 'Jose Dominguez',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$500'
    },
    {
        ID: 'XXXX',
        Nombre: 'Perla Lopez',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$400'
    },
    {
        ID: 'XXXX',
        Nombre: 'Cristopher Rocha',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$350'
    },
    {
        ID: 'XXXX',
        Nombre: 'Victor Dominguez',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$560'
    },
    {
        ID: 'XXXX',
        Nombre: 'Jose Dominguez',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$500'
    },
    {
        ID: 'XXXX',
        Nombre: 'Perla Lopez',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$400'
    },
    {
        ID: 'XXXX',
        Nombre: 'Cristopher Rocha',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$350'
    },
    {
        ID: 'XXXX',
        Nombre: 'Victor Dominguez',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$560'
    },
    {
        ID: 'XXXX',
        Nombre: 'Jose Dominguez',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$500'
    },
    {
        ID: 'XXXX',
        Nombre: 'Perla Lopez',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$400'
    },
    {
        ID: 'XXXX',
        Nombre: 'Cristopher Rocha',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$350'
    },
    {
        ID: 'XXXX',
        Nombre: 'Victor Dominguez',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$560'
    },
    {
        ID: 'XXXX',
        Nombre: 'Jose Dominguez',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$500'
    },
    {
        ID: 'XXXX',
        Nombre: 'Perla Lopez',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$400'
    },
    {
        ID: 'XXXX',
        Nombre: 'Cristopher Rocha',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$350'
    },
    {
        ID: 'XXXX',
        Nombre: 'Victor Dominguez',
        RFC: 'DJSFJDFGd',
        CURP: 'DSGFD',
        IMSS: '$299',
        Real: '$560'
    }
]

const titles = ['Nombre','RFC','CURP','NSS']


const options = [
    {
        link: 'nuevo',
        title: 'Nuevo Empleado'
    },
    {
        link: '/app/nominas/nuevo',
        title: 'Nueva Nómina'
    }
]

export {data , titles, options}