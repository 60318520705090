const inputsEmpleado = [
    {
        etiqueta: "ID",
        placeholder: "----",
        tipo: "text",
        name: "ID",
        value: ""
    },
    {
        etiqueta: "Nombre (s):",
        placeholder: "----",
        tipo: "text",
        name: "nombre",
        value: ""
    },
    {
        etiqueta: "Apellido Paterno",
        placeholder: "----",
        tipo: "text",
        name: "apellidoPaterno",
        value: ""
    },
    {
        etiqueta: "Apellido Materno",
        placeholder: "----",
        tipo: "text",
        name: "apellidoMaterno",
        value: ""
    },
    {
        etiqueta: "NSS",
        placeholder: "----",
        tipo: "text",
        name: "nss",
        value: ""
    },
    {
        etiqueta: "CURP",
        placeholder: "----",
        tipo: "text",
        name: "curp",
        value: ""
    },
    {
        etiqueta: "RFC",
        placeholder: "----",
        tipo: "text",
        name: "rfc",
        value: ""
    },
    {
        etiqueta: "Estado Civil",
        placeholder: "----",
        tipo: "text",
        name: "estadoCivil",
        value: ""
    },
    {
        etiqueta: "Sexo",
        placeholder: "----",
        tipo: "text",
        name: "sexo",
        value: ""
    },
    {
        etiqueta: "Calle",
        placeholder: "----",
        tipo: "text",
        name: "calle",
        value: ""
    },
    {
        etiqueta: "Número Exterior",
        placeholder: "----",
        tipo: "text",
        name: "numeroExterior",
        value: ""
    },
    {
        etiqueta: "Número Interior",
        placeholder: "----",
        tipo: "text",
        name: "numeroInterior",
        value: ""
    },
    {
        etiqueta: "Colonia",
        placeholder: "----",
        tipo: "text",
        name: "colonia",
        value: ""
    },
    {
        etiqueta: "Código Postal",
        placeholder: "----",
        tipo: "text",
        name: "codigoPostal",
        value: ""
    },
    {
        etiqueta: "Municipio",
        placeholder: "----",
        tipo: "text",
        name: "municipio",
        value: ""
    },
    {
        etiqueta: "Estado",
        placeholder: "----",
        tipo: "text",
        name: "estado",
        value: ""
    },
    {
        etiqueta: "Banco",
        placeholder: "----",
        tipo: "text",
        name: "banco",
        value: ""
    },
    {
        etiqueta: "Cuenta",
        placeholder: "----",
        tipo: "text",
        name: "cuenta",
        value: ""
    },
    {
        etiqueta: "Clabe",
        placeholder: "----",
        tipo: "text",
        name: "clabe",
        value: ""
    },
    {
        etiqueta: "Puesto",
        placeholder: "----",
        tipo: "text",
        name: "puesto",
        value: ""
    },
    {
        etiqueta: "Sueldo",
        placeholder: "----",
        tipo: "text",
        name: "sueldo",
        value: ""
    },
    {
        etiqueta: "Fecha Ingreso",
        placeholder: "----",
        tipo: "date",
        name: "ingreso",
        value: ""
    },
    {
        etiqueta: "Fecha de Nacimiento",
        placeholder: "----",
        tipo: "date",
        name: "fecha_nacimiento", //modificar esta linea
        value: ""
    }
];

export default inputsEmpleado